import axiosIns from '@/libs/axios'

export function getPermissionCategories({
  perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/business/permissions/categories', {
    params: {
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export default {
  getPermissionCategories,
}
