<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных роли
      </h4>
      <div class="alert-body">
        Не найдено ни одной роли с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'roles'}"
        >
          Список ролей
        </b-link>
        и выбрать другую.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название роли"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание *"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.desc"
                placeholder="Введите описание"
                :state="get(validation, 'desc[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'desc[0]')"
                class="text-danger"
              >
                {{ get(validation, 'desc[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-card
          no-body
          class="border mt-1"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                icon="LockIcon"
                size="18"
              />
              <span class="align-middle ml-50">Разрешения</span>
            </b-card-title>
          </b-card-header>
          <b-table-simple
            :striped="false"
            :bordered="false"
            :borderless="false"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="m-0"
          >
            <b-tbody
              v-for="permissionCategory in groupedPermissions"
              :key="'c' + permissionCategory.id"
            >
              <b-tr>
                <b-th
                  colspan="2"
                  class="bg-light"
                >
                  {{ permissionCategory.name }}
                </b-th>
              </b-tr>
              <b-tr
                v-for="permission in permissionCategory.permissions"
                :key="'p' + permission.id"
              >
                <b-td>
                  <b-form-checkbox
                    v-model="item.permissions[permission.code]"
                    :value="1"
                    :unchecked-value="0"
                    class="pl-0"
                  />
                </b-td>
                <b-td>
                  <div class="">
                    {{ permission.name }}
                  </div>
                  <div class="small text-secondary">
                    {{ permission.desc }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BThead, BTr, BTd, BTh,
} from 'bootstrap-vue'
import get from 'lodash/get'
import {
  getCurrentInstance, ref, onMounted, computed,
} from 'vue'
import { getPermissions } from '@/services/main-api/business/permissions/permissions'
import { getPermissionCategories as getPermissionCategoriesApi } from '@/services/main-api/business/permissions/categories'
import { updateRole, getRole, updateRolePermissions } from '@/services/main-api/business/roles'
import useCrudEdit from '@/composables/useCrudEdit'

export default {
  name: 'EditCompanyView',
  components: {
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BTab,
    BTabs,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,
    BTh,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const permissions = ref([])
    const permissionCategories = ref([])
    const getItemError = ref(false)

    const getAllPermissions = async () => {
      try {
        const res = await getPermissions({
          perPage: 1000,
          page: 1,
        })
        permissions.value = res.data.data
      } catch (e) {
        permissions.value = []
      }
    }

    const getPermissionCategories = async () => {
      try {
        const res = await getPermissionCategoriesApi({
          perPage: 1000,
          page: 1,
        })
        permissionCategories.value = res.data.data
      } catch (e) {
        permissionCategories.value = []
      }
    }

    const editPermissions = async () => {
      const tempPermissions = Object.entries(instance.item.permissions)
        .map(i => ({
          code: i[0],
          value: i[1],
        }))

      if (tempPermissions.length) {
        try {
          await updateRolePermissions({
            id: instance.item.id,
            permissions: tempPermissions,
          })
        } catch (e) {
          console.log(e)
        }
      }
    }

    const getItem = async () => {
      try {
        const res = await getRole(props.id)
        instance.item = res.data.data
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await Promise.all([
        updateRole({
          id: instance.item.id,
          name: instance.item.name,
          desc: instance.item.desc,
        }),
        editPermissions(),
      ])
    }

    const groupedPermissions = computed(() => permissionCategories.value.map(c => ({
      ...c,
      permissions: permissions.value.filter(p => p.permission_category_id === c.id),
    })))

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        name: '',
        desc: '',
        permissions: {},
      },
      validation: {
        rules: {
          name: 'required',
          desc: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
          'required.desc': 'Укажите описание',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Роль была изменена.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
      getMoreData: async () => {
        await Promise.all([
          getAllPermissions(),
          getPermissionCategories(),
        ])
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      permissions,
      permissionCategories,
      groupedPermissions,

      get,
      getItemError,
    }
  },
}
</script>

<style lang="scss">
</style>
