import axiosIns from '@/libs/axios'

export function getRoles({
  company, perPage, sortedBy, orderBy, page, search, searchFields, searchJoin,
}) {
  return axiosIns.get('/business/roles', {
    params: {
      company,
      perPage,
      sortedBy,
      orderBy,
      page,
      search,
      searchFields,
      searchJoin,
    },
  })
}

export function getRole(id) {
  return axiosIns.get(`/business/roles/${id}`)
}

export function deleteRole(id) {
  return axiosIns.delete(`/business/roles/${id}`)
}

export function createRole({ name, desc, company_id }) {
  return axiosIns.post('/business/roles', {
    name,
    desc,
    company_id,
  })
}

export function updateRole({ id, name, desc }) {
  return axiosIns.patch(`/business/roles/${id}`, {
    name,
    desc,
  })
}

export function updateRolePermissions({ id, permissions }) {
  return axiosIns.patch(`/business/roles/${id}/permissions`, {
    permissions,
  })
}

export default {
  getRole,
  deleteRole,
  createRole,
  updateRole,
  getRoles,
}
