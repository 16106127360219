import {
  reactive, toRefs, onMounted, getCurrentInstance,
} from 'vue'
import { get, cloneDeep } from 'lodash'
import * as Validator from 'validatorjs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCrudEdit({
  item = {},
  getItem = async () => {},
  getMoreData = async () => {},
  updateEntity = () => {},
  getTextAfterUpdate = () => 'Сущность была изменена.',
  runAfterUpdate = () => {},
  catchUpdateItem = () => {},
  validation = {}, // { rules: {},  customErrorMessages, {} }  https://github.com/mikeerickson/validatorjs
}) {
  const instance = getCurrentInstance().proxy

  const data = reactive({
    item: cloneDeep(item),
    loading: false,
    validation: {},
    error_message: '',
  })

  const getData = async () => {
    await Promise.all([
      getItem(),
      getMoreData(),
    ])
  }

  const clearValidation = () => {
    data.validation = {}
    data.error_message = ''
  }

  const checkValidation = () => {
    clearValidation()
    let hasError = false

    if (validation.rules !== undefined) {
      const dataValidator = new Validator(data.item, validation.rules, validation.customErrorMessages)
      if (dataValidator.fails()) {
        hasError = true
        data.validation = dataValidator.errors.all()
      }
    }
    return !hasError
  }

  const catchValidation = e => {
    data.validation = get(e.response, 'data.errors')
    data.error_message = get(e.response, 'data.error', '')
  }

  const clearForm = () => {
    clearValidation()
    data.item = cloneDeep(item)
  }

  const updateItem = async () => {
    if (!checkValidation()) {
      return
    }
    data.loading = true
    try {
      await updateEntity()
      instance.$toast({
        component: ToastificationContent,
        props: {
          title: 'Изменено!',
          icon: 'BellIcon',
          text: getTextAfterUpdate(),
          variant: 'success',
        },
      })
      clearForm()
      runAfterUpdate()
    } catch (e) {
      catchValidation(e)
      catchUpdateItem()
    } finally {
      data.loading = false
    }
  }

  onMounted(async () => {
    data.loading = true
    await getData()
    data.loading = false
  })

  return {
    ...toRefs(data),
    clearForm,
    updateItem,
  }
}
